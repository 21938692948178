<template>
  <div>
    <b-table
      :items="orderedActs"
      :fields="fields"
      small
      caption-top
      bordered
      show-empty
      empty-text="No hay Actas para mostrar."
    >
      <template #table-caption>Listado de Actas</template>
      <template #cell(start_date)="data">
        {{ data.value | toDateTime }}
      </template>
      <template #cell(end_date)="data">
        {{ data.value | toDateTime }}
      </template>
      <template #cell(agreement)="data">
        <span v-b-tooltip.v-secondary.noninteractive="'Acuerdos Cumplidos'">
          {{ data.item.agreements_fullfiled.length }}
        </span>
        /
        <span v-b-tooltip.v-secondary.noninteractive="'Total de Acuerdos'">
          {{ data.item.agreements.length }}
        </span>
      </template>
      <template #cell(actions)="data">
        <b-button @click="emitOpenAct(data.item)" size="sm">Abrir</b-button>
        <b-button
          v-if="
            user &&
            (data.item.created_by == user.id ||
              data.item.can_edit_user_list.includes(user.id))
          "
          size="sm"
          variant="none"
          class="ml-1"
          @click="askForDeleteAct(data.item.id)"
        >
          <b-icon-trash></b-icon-trash>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { toast } from "@/utils/utils";
import { mapGetters } from "vuex";

export default {
  name: "ActList",
  props: {
    Acts: {
      type: Array,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "number",
          label: "Número",
          sortable: true,
          thClass: "th-class",
        },
        {
          key: "start_date",
          label: "Fecha Inicial",
          thClass: "th-class",
          sortable: true,
        },
        {
          key: "end_date",
          label: "Fecha de Término",
          thClass: "th-class",
          sortable: true,
        },
        {
          key: "agreement",
          label: "Acuerdos",
          thClass: "th-class",
          sortable: false,
          headerTitle: "Cumplidos / Totales",
        },
        {
          key: "actions",
          thClass: "th-class",
          label: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    orderedActs() {
      let list = [];
      let index = 1;
      this.Acts.forEach((act) => {
        act.number = index;
        list.push(act);
        index += 1;
      });
      return list;
    },
  },
  methods: {
    emitOpenAct(act) {
      this.$emit("open", act.id);
    },
    askForDeleteAct(act_id) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Acta?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteAct(act_id);
        }
      });
    },
    deleteAct(act_id) {
      this.$restful.Delete(`/cideu/act/${act_id}/`).then(() => {
        this.$emit("deleted", act_id);
        toast("Acta eliminada.");
      });
    },
  },
};
</script>

<style>
.th-class {
  background: var(--primary-color) !important;
}
</style>